import React from 'react'
import {FormattedMessage, useIntl} from "react-intl";

import {AsideMenuItem} from './AsideMenuItem'
import clsx from "clsx";
import {checkIsActive, KTSVG} from "../../../helpers";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {Dropdown1, Dropdown2} from "../../../partials";

export const AsideMenuDocs = () => {
    const intl = useIntl();
    // const {pathname} = useLocation();
    return (
        <>
            <div className='menu-item'>
                <h4 className='menu-content text-muted mb-0 fs-7 text-uppercase'>
                    <FormattedMessage id="DOCS.MENU.TITLE" defaultMessage='SECTIONS'/>
                </h4>
            </div>
            <AsideMenuItem to='/home' title={intl.formatMessage({id: "DOCS.MENU.OVERVIEW", defaultMessage: 'Overview'})}/>
            <AsideMenuItem to='/extension' title={intl.formatMessage({id: "DOCS.MENU.EXTENSION", defaultMessage: 'Extension'})}/>
            <AsideMenuItem to='/users' title={intl.formatMessage({id: "DOCS.MENU.USERS", defaultMessage: 'Users'})}/>
            <AsideMenuItem to='/projects' title={intl.formatMessage({id: "DOCS.MENU.PROJECTS", defaultMessage: 'Projects'})}/>
            <AsideMenuItem to='/tests' title={intl.formatMessage({id: "DOCS.MENU.TESTS", defaultMessage: 'Tests'})}/>
            <AsideMenuItem to='/rest' title={intl.formatMessage({id: "DOCS.MENU.REST", defaultMessage: 'Rest API'})}/>
            <AsideMenuItem to='/suites' title={intl.formatMessage({id: "DOCS.MENU.SUITES", defaultMessage: 'Suites'})}/>
            <AsideMenuItem to='/execute' title={intl.formatMessage({id: "DOCS.MENU.EXECUTE", defaultMessage: 'Execute'})}/>
            <AsideMenuItem to='/reporting' title={intl.formatMessage({id: "DOCS.MENU.REPORTING", defaultMessage: 'Reporting'})}/>
            <>
                <div className='menu-item mt-10'>
                    <h4 className='menu-content text-muted mb-0 fs-6 fw-bold text-uppercase'>Plugins</h4>
                </div>
                {/*<AsideMenuItem to='https://tigmat.io' title='Firefox' />*/}
                <div className='menu-item'>
                    <a className='menu-link' href='https://chrome.google.com/webstore/detail/tigmat-ide-the-functional/chjoocjagcohmngaiciohfcjohfdaemg' target='_blank'>
                        <span className='menu-icon'>
                            <i className="fab fa-chrome"/>
                        </span>
                        <span className='menu-title'>Chrome</span>
                    </a>
                </div>

                <div className='menu-item'>
                    <a className='menu-link' href='https://addons.mozilla.org/en-US/firefox/addon/tigmat-ide/' target='_blank'>
                        <span className='menu-icon'>
                            <i className="fab fa-firefox-browser"/>
                        </span>
                        <span className='menu-title'>Firefox</span>
                    </a>
                </div>

                <div className='menu-item'>
                    <a className='menu-link' href='https://microsoftedge.microsoft.com/addons/detail/mlmglfbigjdofppdfgifdeopcijljnbe' target='_blank'>
                        <span className='menu-icon'>
                            <i className="fab fa-edge"/>
                        </span>
                        <span className='menu-title'>Edge</span>
                    </a>
                </div>
            </>
        </>
    );
};
