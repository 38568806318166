import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Reporting = props => {

	return (
		<div className = 'section'>

			<h1><FormattedMessage id="REPORTING.TEST.COMPAIGN.REPORT"
								  defaultMessage='Test campaign report'/></h1>

			<h3><strong><FormattedMessage id="REPORTING.TEST.RESULTS"
										  defaultMessage='Test results'/></strong></h3>

			<p><FormattedMessage id="REPORTING.AFTER.END.EXECUTION"
								 defaultMessage='At the end of an iteration, if all the tests are successful, then a green button is displayed. Otherwise, the button is red'/>.</p>

			<p><FormattedMessage id="REPORTING.DISPLAY.DETAILED.RESULTS.RESULTS"
								 defaultMessage='Whatever the results, by clicking on the button, you access the detailed results of each suite and each test'/>.</p>

			<p><FormattedMessage id="REPORTING.SUITE.LEVEL"
								 defaultMessage='At the Suite level we find the following results'/>:</p>

			<ol>
				<li><strong><FormattedMessage id="NAME"
									  defaultMessage='Name'/>: </strong>
					<FormattedMessage id="REPORTING.NAME.SUITE.DESCRIPTION"
									  defaultMessage='the name of the suite'/>.</li>
				<li><strong><FormattedMessage id="TIME"
									  defaultMessage='Time'/>: </strong>
					<FormattedMessage id="REPORTING.TIME.CUMUL"
									  defaultMessage='the cumulative time (in seconds) of all the tests in the suite'/>.</li>
				<li><strong><FormattedMessage id="REPORTING.TESTS"
									  defaultMessage='Tests'/>: </strong>
					<FormattedMessage id="REPORTING.TESTS.NUMBER"
									  defaultMessage='the number of tests in the suite'/>.</li>
				<li><strong><FormattedMessage id="REPORTING.ERRORS"
									  defaultMessage='Errors'/>: </strong>
					<FormattedMessage id="REPORTING.ERRORS.NUMBER"
									  defaultMessage='the number of errors. An error means an unexpected problem (for example, an element is not found by its id)'/>.</li>
				<li><strong><FormattedMessage id="REPORTING.FAILURES"
									  defaultMessage='Failures'/>: </strong>
					<FormattedMessage id="REPORTING.FAILURES.NUMBER"
									  defaultMessage='The number of failures. Failure means that the test result is false (for example, the text displayed in a text box is different from what is expected in the test)'/>.</li>
			</ol>

			<p><FormattedMessage id="REPORTING.DEVELOP.SUITE.TO.DISCOVER"
								 defaultMessage='Develop suite to discover the results of each test'/>.<br/>
				<FormattedMessage id="REPORTING.TEST.LEVEL"
								  defaultMessage='At the test level we have the following results'/>:</p>

			<ol>
				<li><strong><FormattedMessage id="NAME"
											  defaultMessage='Name'/>: </strong>
					<FormattedMessage id="REPORTING.NAME.TEST.DESCRIPTION"
									  defaultMessage='the name of the test'/>.</li>
				<li><strong><FormattedMessage id="TIME"
											  defaultMessage='Time'/>: </strong>
					<FormattedMessage id="REPORTING.TIME.CUMUL.TEST"
									  defaultMessage='Time (in seconds)'/>.</li>
				<li><strong><FormattedMessage id="REPORTING.ERRORS"
											  defaultMessage='Errors'/>: </strong>
					<FormattedMessage id="REPORTING.ERRORS.MESSAGE"
									  defaultMessage='a clear message of the error encountered (example: The element id = loginId is not found)'/>.</li>
				<li><strong><FormattedMessage id="REPORTING.FAILURES"
											  defaultMessage='Failures'/>: </strong>
					<FormattedMessage id="REPORTING.FAILURES.MESSAGE"
									  defaultMessage='a clear message of the failure encountered (example: The text "Your account has been created successfully" is not present.)'/>.</li>
			</ol>

			<h3><strong><FormattedMessage id="VIDEO"
										  defaultMessage='Video'/></strong></h3>

			<p><FormattedMessage id="REPORTING.BOTTOM.PAGE"
								 defaultMessage='At each iteration, you can view the test video. This is useful for checking the behavior of a particular test'/>.</p>

		</div>
	);
}

export default Reporting;