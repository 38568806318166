/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useLang, setLanguage} from '../../_metronic/i18n/Metronici18n'
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import {DropdownTopbarItemToggler} from "./DropdownTopbarItemToggler";
import {FormattedMessage} from "react-intl";

const languages = [
    {
        lang: 'en',
        name: 'English',
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
        lang: 'fr',
        name: 'Français',
        flag: toAbsoluteUrl('/media/flags/france.svg'),
    },
]

const Languages: FC = () => {
    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)
    return (
        <Dropdown drop="down" align='end'>
            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-my-cart"
            >
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip id="language-panel-tooltip">
                            <FormattedMessage id="TOPBAR.SELECT.LANGUAGE"
                                              defaultMessage='Select Language'/></Tooltip>
                    }
                >
                    <a href='#' className=' px-5'>
                        <span className='fs-8 rounded bg-white text-gray-600 fw-bold px-3 py-2 translate-middle-y top-50 end-0'>
                        {currentLanguage?.name}{' '}
                            <img
                                className='w-15px h-15px rounded-1 ms-2'
                                src={currentLanguage?.flag}
                                alt='metronic'
                            />
                        </span>
                    </a>
                    {/*<div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">*/}
                    {/*    <img*/}
                    {/*        className="rounded" width='25px' height='25px'*/}
                    {/*        src={currentLanguage ? currentLanguage.flag : ''}*/}
                    {/*        alt={currentLanguage ? currentLanguage.name : ''}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </OverlayTrigger>
            </Dropdown.Toggle>

            {/*<div className='menu-sub menu-sub-dropdown w-175px py-4'>*/}
            {/*    {languages.map((l) => (*/}
            {/*        <div*/}
            {/*            className='menu-item px-3'*/}
            {/*            key={l.lang}*/}
            {/*            onClick={() => {*/}
            {/*                setLanguage(l.lang)*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <a*/}
            {/*                href='#'*/}
            {/*                className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}*/}
            {/*            >*/}
            {/*    <span className='symbol symbol-20px me-4'>*/}
            {/*      <img className='rounded-1' src={l.flag} alt='metronic' />*/}
            {/*    </span>*/}
            {/*                {l.name}*/}
            {/*            </a>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</div>*/}

            <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                {/*<ul className = "">*/}
                {/*  {languages.map((language) => (*/}
                {/*      <li key = {language.lang} style={{padding: '0'}} className='lang'>*/}
                {/*        <a href = "#"*/}
                {/*           onClick = {() => setLanguage(language.lang)}*/}
                {/*           className = "" style={{display: 'flex', alignItems: 'center',*/}
                {/*          fontSize: '1rem', }}>*/}
                {/*	                <span className = "mr-3">*/}
                {/*	                  <img src = {language.flag} alt = {language.name} width = '20px' height = '20px'/>*/}
                {/*	                </span>*/}
                {/*          <span className = "">{language.name}</span>*/}
                {/*        </a>*/}
                {/*      </li>*/}
                {/*  ))}*/}
                {/*</ul>*/}
                {languages.map((l) => (
                    <div
                        className='menu menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-1 fs-6'
                        data-kt-menu='true' key={l.lang}>
                        <div className='menu-item px-3' onClick={() => {setLanguage(l.lang)}}>
                            <a href='#' className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}>
                                <span className='symbol symbol-20px me-4'>
                                    <img className='rounded-1' src={l.flag} alt='metronic'/>
                                </span>
                                {l.name}
                            </a>
                        </div>
                    </div>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export {Languages}
