import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Projects = props => {

	return (
		<div className = 'section'>

			<h1><FormattedMessage id="PROJECTS.MANAGEMENT"
								  defaultMessage='Projects management'/></h1>

			<h3><strong><FormattedMessage id="PROJECTS.CREATE"
										  defaultMessage='Creating a project'/></strong></h3>

			<p><FormattedMessage id="PROJECTS.ALL.COMPONENTS.BELONG.PROJECT"
								 defaultMessage='Tigmat allows to manage several types of projects: Web, Android, Web Android and API.'/></p>

			<p><FormattedMessage id="PROJECTS.HOW.CREATE.NEW.PROJECT"
								 defaultMessage='To create a new project : Administration -> Projects -> New project'/>.</p>

			<p><FormattedMessage id="PROJECTS.FILL.FOLOWING.FIELDS"
								 defaultMessage='Please fill in the following fields'/>:</p>
			<p><strong><FormattedMessage id="PROJECTS.COMMON.FIELDS"
								 defaultMessage='Common fields'/>:</strong></p>

			<ul>
				<li><strong><FormattedMessage id="NAME"
									  defaultMessage='Name'/> : </strong>
					<FormattedMessage id="PROJECTS.PROJECT.NAME.UNIQUE"
									  defaultMessage='must be unique'/>.</li>
				<li><strong><FormattedMessage id="TYPE"
											  defaultMessage='Type'/> : </strong>
					<FormattedMessage id="PROJECTS.TYPES.PROJECT"
									  defaultMessage='Web, Android Application, Chrome Android or REST API'/>.</li>

				{/*<li><strong><FormattedMessage id="PROJECTS.LIVE.MODE"*/}
				{/*					  defaultMessage='Live mode'/> : </strong>*/}
				{/*	<FormattedMessage id="PROJECTS.LIVE.MODE.DESCRIPTION"*/}
				{/*					  defaultMessage='In the case of manual tests, you can watch the execution of the tests live'/>.</li>*/}
				<li><strong><FormattedMessage id="PROJECTS.AUTOMATIC.EXECUTION"
											  defaultMessage='Automatic execution'/> : </strong>
					<FormattedMessage id="PROJECTS.AUTOMATIC.EXECUTION.DESCRIPTION"
									  defaultMessage='If you want to launch the tests automatically at a specific time'/>.</li>

				<li><strong><FormattedMessage id="PROJECTS.DESCRIPTION"
											  defaultMessage='Description'/> : </strong>
					<FormattedMessage id="PROJECTS.DESCRIPTION.DETAIL"
									  defaultMessage='You can detail your project, strategy or test plan'/>.</li>

				<li><strong><FormattedMessage id="PROJECTS.USERS"
											  defaultMessage='Users'/> : </strong>
					<FormattedMessage id="PROJECTS.USERS.DETAIL"
									  defaultMessage='The list on the left contains all the users associated with this account, while the one on the right contains the users assigned to this project'/>.</li>
			</ul>
			<p><strong><FormattedMessage id="PROJECTS.FIELDS.WEB"
										 defaultMessage='Web Project'/>:</strong></p>
			<ul>
				<li><strong><FormattedMessage id="PROJECTS.URL"
											  defaultMessage='URL'/> : </strong>
					<FormattedMessage id="PROJECTS.URL.DES"
									  defaultMessage='The URL of the application to be tested'/></li>
				<li><strong><FormattedMessage id="PROJECTS.OS"
											  defaultMessage='Operating System'/> : </strong>
					<FormattedMessage id="PROJECTS.OS.DES"
									  defaultMessage='Windows or Linux'/>.</li>
				<li><strong><FormattedMessage id="PROJECTS.BROWSER"
											  defaultMessage='Browser'/> : </strong>
					<FormattedMessage id="PROJECTS.BROWSER.DES"
									  defaultMessage='Firefox, Chrome or Edge'/>.</li>
				<li><strong><FormattedMessage id="PROJECTS.BROWSER.VERSION"
											  defaultMessage="Browser's Version"/> : </strong>
					<FormattedMessage id="PROJECTS.BROWSER.VERSION.DES"
									  defaultMessage='Latest (Latest version), Latest-1 (penultimate version) ...'/>.</li>
			</ul>

			<p><strong><FormattedMessage id="PROJECTS.FIELDS.ANDROID"
										 defaultMessage='Android Project'/>:</strong></p>
			<ul>
				<li><strong><FormattedMessage id="PROJECTS.ANDROID.APPLICATION"
											  defaultMessage='The Android application'/> : </strong>
					<FormattedMessage id="PROJECTS.IMPORT.ANDROID.APPLICATION"
									  defaultMessage='Import the (apk format) application to be tested'/>.<br/><br/>
					<FormattedMessage id="PROJECTS.IMPORT.ANDROID.APPLICATION.AFTER"
									  defaultMessage='For Android projects, after the file is imported, the project is created and you are directed to the project modification page'/>.<br/>
					<FormattedMessage id="PROJECTS.IMPORT.ANDROID.APPLICATION.GROUPE1"
									  defaultMessage='In this page, you are prompted to create a device group'/>.<br/>
					<FormattedMessage id="PROJECTS.IMPORT.ANDROID.APPLICATION.GROUPE2"
									  defaultMessage='A device group can consist of one or more devices on which testing is desired'/>.<br/>
					<strong><FormattedMessage id="PROJECTS.IMPORT.ANDROID.APPLICATION.DEVICE"
											  defaultMessage='Note: When you run tests on a group of multiple devices, the total execution time is the sum of the durations for each device'/>.</strong>
				</li>
			</ul>

			<p><strong><FormattedMessage id="PROJECTS.FIELDS.ANDROID.WEB"
										 defaultMessage='Android Web Project'/>:</strong></p>
			<ul>
				<li><strong><FormattedMessage id="PROJECTS.URL"
											  defaultMessage=''/> : </strong>
					<FormattedMessage id="PROJECTS.URL.DES"
									  defaultMessage=''/></li>
			</ul>

			<p><strong><FormattedMessage id="PROJECTS.FIELDS.API"
										 defaultMessage='API Project'/>:</strong></p>
			<ul>
				<li><strong><FormattedMessage id="PROJECTS.URL"
											  defaultMessage=''/> : </strong>
					<FormattedMessage id="PROJECTS.URL.DES"
									  defaultMessage=''/></li>
			</ul>


			<p><FormattedMessage id="PROJECTS.DEFAULT.VALUES.ITERATION"
								 defaultMessage='The values entered for the project will be used to initialize an execution. However, you can modify them for each run (see the "Iteration" chapter).'/>.</p>

			<p><FormattedMessage id="PROJECTS.AFTER.SAVING.BECOMES.DEFAULT.PROJECT"
								 defaultMessage='Once saved, if the project you just created is the first one, it will become your default project. Otherwise, a dialog box will appear asking if you want to make this your default project'/>.</p>


			<h3><strong><FormattedMessage id="PROJECTS.SELECTING.PROJECT"
										  defaultMessage='Change project'/></strong></h3>

			<p><FormattedMessage id="PROJECTS.SWITCH.PROJECT"
								 defaultMessage='In the Project list at the top left, you can switch between projects'/></p>

		</div>
	);
}

export default Projects;