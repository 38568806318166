import React, {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../_metronic/layout/core'
// import { StockholmIcons } from "./icons/StockholmIcons";
import Home from "./tigmat/Home";
import Users from "./tigmat/Users";
import {FormattedMessage, useIntl} from "react-intl";
import Projects from "./tigmat/Projects";
import Tests from "./tigmat/Tests";
import Suites from "./tigmat/Suites";
import Execute from "./tigmat/Execute";
import Reporting from "./tigmat/Reporting";
import Extension from "./tigmat/Extension";
import Rest from "./tigmat/Rest";

const gettingStartedBC: Array<PageLink> = [
    // {
    //     title: 'Getting started',
    //     isSeparator: false,
    // },
    {
        title: '',
        isSeparator: true,
    },
]

// const baseBC: Array<PageLink> = [
//     {
//         title: 'Base',
//         isSeparator: false,
//     },
//     {
//         title: '',
//         isSeparator: true,
//     },
// ]
//
// const baseHelpersBC: Array<PageLink> = [
//     {
//         title: 'Base',
//         isSeparator: false,
//     },
//     {
//         title: '',
//         isSeparator: true,
//     },
//     {
//         title: 'Helpers',
//         isSeparator: false,
//     },
//     {
//         title: '',
//         isSeparator: true,
//     },
// ]
//
// const iconsBC: Array<PageLink> = [
//     {
//         title: 'Icons',
//         isSeparator: false,
//     },
//     {
//         title: '',
//         isSeparator: true,
//     },
// ]

const DocsPage: FC = () => {
    const intl = useIntl();
    return <Switch>

        <Route path='/home'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.OVERVIEW", defaultMessage: 'Overview'})}
            </PageTitle>
            <Home/>
        </Route>
        <Route path='/users'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.USERS", defaultMessage: 'Users'})}
            </PageTitle>
            <Users/>
        </Route>
        <Route path='/projects'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.PROJECTS", defaultMessage: 'Projects'})}
            </PageTitle>
            <Projects/>
        </Route>
        <Route path='/tests'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.TESTS", defaultMessage: 'Tests'})}
            </PageTitle>
            <Tests/>
        </Route>
        <Route path='/suites'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.SUITES", defaultMessage: 'Suites'})}
            </PageTitle>
            <Suites/>
        </Route>
        <Route path='/execute'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.EXECUTE", defaultMessage: 'Execute'})}
            </PageTitle>
            <Execute/>
        </Route>
        <Route path='/reporting'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.REPORTING", defaultMessage: 'Reporting'})}
            </PageTitle>
            <Reporting/>
        </Route>

        <Route path='/extension'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.EXTENSION", defaultMessage: 'Extension'})}
            </PageTitle>
            <Extension/>
        </Route>

        <Route path='/rest'>
            <PageTitle breadcrumbs={gettingStartedBC}>
                {intl.formatMessage({id: "DOCS.MENU.REST", defaultMessage: 'Rest API'})}
            </PageTitle>
            <Rest/>
        </Route>

        {/*<Redirect from='/docs' exact={true} to='/docs/quick-start'/>*/}
        <Redirect to='/home'/>
    </Switch>;
}


export {DocsPage}
