import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/fr'

import enMessages from './tigmat/en.json'
import frMessages from './tigmat/fr.json'

const allMessages = {
  en: enMessages,
  fr: frMessages,
}

const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
  )
}

export {I18nProvider}
