import React from "react";

// import "./style/style.css";
import {FormattedMessage} from "react-intl";

const Home = () => {

	return (
		<div className='section'>
			<h1 className='section-head'>
				<FormattedMessage id="DOCS.OVERVIEW.TITLE" defaultMessage='Overview'/>
			</h1>
			<div className='section-content'>
				<p><FormattedMessage id="DOCS.OVERVIEW.TEXT.1"
									 defaultMessage='Tigmat is a cloud-based NoCode test automation solution designed to provide a streamlined testing experience for non-technical users. With Tigmat, the traditional challenges of test automation for applications such as high costs, long test schedules, and complex testing are significantly reduced. Tigmat offers a practical and efficient solution for automating tests.'/>
				</p>

				<p><FormattedMessage id="DOCS.OVERVIEW.TEXT.2"
									 defaultMessage='This documentation will guide you through using Tigmat. It will provide you with all the information you need to understand how Tigmat works, best practices, as well as tips for optimizing your account usage.'/>
				</p>

				<p><FormattedMessage id="DOCS.OVERVIEW.TEXT.3"
									 defaultMessage='This guide is structured as follows:'/></p>

				<p><strong><FormattedMessage id="DOCS.OVERVIEW.TEXT.4" defaultMessage='1. User account management'/></strong><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.5"
															  defaultMessage='User management is an essential and independent part.'/><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.6"
															  defaultMessage='First, create an account. Afterwards, you will be able to manage the users in your team by adding or removing them.'/>
				</p>

				<p><strong><FormattedMessage id="DOCS.OVERVIEW.TEXT.7" defaultMessage='2. Project management (Web and mobile)'/></strong><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.8"
															  defaultMessage='You can manage your projects.'/><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.9"
															  defaultMessage='You can also assign users to your projects based on project teams.'/>
				</p>

				<p><strong><FormattedMessage id="DOCS.OVERVIEW.TEXT.10" defaultMessage='3. Management of domains and tests'/></strong><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.11"
																	defaultMessage='In this section, you can create tests in different ways'/><br/>
					&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.12"
																			   defaultMessage='- Roll out your scenario on a browser and the platform will handle capturing your actions and recording them.'/><br/>
					&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.13"
																			   defaultMessage='- Import already existing test cases'/><br/>
					&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.14"
																			   defaultMessage='- Manually create tests'/><br/>

					<strong><FormattedMessage id="DOCS.OVERVIEW.TEXT.15" defaultMessage='4. Management of suites'/></strong><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.16"
																	defaultMessage='After creating test cases, you can organize them into suites to form a complete scenario.'/>
				</p>

				<p><strong><FormattedMessage id="DOCS.OVERVIEW.TEXT.17" defaultMessage='5. Sprint management'/></strong><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.18"
																	defaultMessage='Tigmat is adapted to the Agile Scrum methodology.'/><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="DOCS.OVERVIEW.TEXT.19"
																		  defaultMessage='You can trigger the execution of your test campaigns to be in sync with the development teams.'/>
				</p>

				<p>&nbsp;</p>
			</div>
		</div>
	);
}

export default Home;