import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Suites = props => {

	return (
		<div className = 'section'>

				<h2><FormattedMessage id="SUITES.MANAGEMENT"
									  defaultMessage='Test Suites Management'/></h2>

				<h3><strong><FormattedMessage id="SUITES.SUITE.MANAGEMENT"
											  defaultMessage='Suite management'/></strong></h3>

				<p><FormattedMessage id="SUITES.CREATE.SUITE"
									 defaultMessage='A test suite allows you to create a complete scenario from several tests'/>
					<strong> <FormattedMessage id="SUITES.HOW.CREATE.SUITE"
											  defaultMessage='Suites -> add a suite'/></strong></p>

				<br/>
				<h2><strong><FormattedMessage id="SUITES.WEB.ANDROID.PROJECT"
										  defaultMessage='Web & Android project'/></strong></h2>
				<h3><strong><FormattedMessage id="SUITES.TEST.MANAGEMENT"
											  defaultMessage='Test management'/></strong></h3>

				<p><FormattedMessage id="SUITES.ONCE.SUITE.ADD.TEST"
									 defaultMessage='Once the suite is created. You can add tests to it'/>.</p>

				<p><FormattedMessage id="SUITES.TO.ADD.TEST"
									 defaultMessage='To add a test'/>
					<strong> <FormattedMessage id="SUITES.HOW.ADD.TESTS"
											  defaultMessage='in the Suites tab -> click on suite -> Tab: Test cases -> Add tests'/></strong></p>

				<p><FormattedMessage id="SUITES.POPUP.CHOOSE.TESTS"
									 defaultMessage='In the popup displayed, choose the tests to be assigned to the suite and validate'/>.</p>

				<p><FormattedMessage id="SUITES.TESTS.ORDER"
									 defaultMessage='You can always change the order of the tests below. To do this, identify the test to be moved and
					click on the button to the left of the test, then move the mouse while maintaining the click towards
					the desired position'/>.</p>

			<h3><strong><FormattedMessage id="SUITES.TEST.REQUISITE.MANAGEMENT"
										  defaultMessage='Prerequisites and Postrequisites'/></strong></h3>
			<p><FormattedMessage id="SUITES.TEST.REQUISITE.DESC.1"
										 defaultMessage='Sometimes we need to execute a Rest API call to test the result on the GUI, in this case we can use the prerequisites and post-requisites'/></p>
			<p><FormattedMessage id="SUITES.TEST.REQUISITE.DESC.2"
								 defaultMessage='The prerequisites will be executed at the beginning of the suite, before the test cases are executed'/></p>
			<p><FormattedMessage id="SUITES.TEST.REQUISITE.DESC.3"
								 defaultMessage='The postrequisites will be executed at the end of the suite, after all the test cases of the suite have been executed'/></p>
			<p><FormattedMessage id="SUITES.TEST.REQUISITE.DESC.4"
								 defaultMessage='In the Suites tab -> a suite -> Tab: Prerequisites/Postrequisites -> Add'/></p>
			<p><FormattedMessage id="SUITES.TEST.REQUISITE.DESC.5"
								 defaultMessage='A popup displays the list of Rest APIs already created, choose the prerequisites/postrequisites to assign to the suite and validate'/></p>


			<br/>
			<h2><strong><FormattedMessage id="SUITES.API.PROJECT"
										  defaultMessage='REST API projects'/></strong></h2>
			<h3><strong><FormattedMessage id="SUITES.API.MANAGEMENT"
										  defaultMessage='API request management'/></strong></h3>

			<p><FormattedMessage id="SUITES.API.TO.ADD.API"
								 defaultMessage='To add an API request, '/>
				<strong> <FormattedMessage id="SUITES.API.HOW.ADD.API"
										   defaultMessage='in the Suites tab -> a suite -> Tab: API tests -> Add'/></strong></p>
			<p><FormattedMessage id="SUITES.API.POPUP.CHOOSE.API"
								 defaultMessage='In the popup displayed, choose the requests to assign to the suite and validate'/>.</p>
			<p><FormattedMessage id="SUITES.API.ORDER"
								 defaultMessage='You can always change the order of the requests in the suite'/>.</p>

			<h3><strong><FormattedMessage id="SUITES.API.AUTH.MANAGEMENT"
										  defaultMessage='Authentication management'/></strong></h3>
			<p><FormattedMessage id="SUITES.API.AUTH.DESC.1"
								 defaultMessage='Often API requests require authentication'/>.</p>
			<p><FormattedMessage id="SUITES.API.AUTH.DESC.2"
								 defaultMessage='To avoid declaring the same authentication for each API request, simply declare it at the suite level'/>.</p>
			<p><FormattedMessage id="SUITES.API.AUTH.DESC.3"
								 defaultMessage='All requests can inherit the authentication of the suite'/>.</p>

			<h5><strong><FormattedMessage id="SUITES.API.AUTH.token"
										  defaultMessage='Case : Bearer token'/></strong></h5>
			<p><FormattedMessage id="SUITES.API.AUTH.token.1"
								 defaultMessage='In the case of JWT tokens, we often have the following scenario'/>:</p>
			<p><FormattedMessage id="SUITES.API.AUTH.token.2"
								 defaultMessage='- The first API request allows authentication with a login and a password'/>.</p>
			<p><FormattedMessage id="SUITES.API.AUTH.token.3"
								 defaultMessage='- The response to this request contains the token'/>.</p>
			<p><FormattedMessage id="SUITES.API.AUTH.token.4"
								 defaultMessage='- We will extract the token into a variable (see response extraction in Rest API)'/>.</p>
			<p><FormattedMessage id="SUITES.API.AUTH.token.5"
								 defaultMessage='- We can find this token and all the extracted variables in the part Authentication -> Bearer -> variable'/>.</p>
			<p><FormattedMessage id="SUITES.API.AUTH.token.6"
								 defaultMessage=''/>.</p>

			<h3><strong><FormattedMessage id="SUITES.API.VARIABLE"
										  defaultMessage='Variable management'/></strong></h3>
			<p><FormattedMessage id="SUITES.API.VARIABLE.DESC.1"
								 defaultMessage='You can declare variables to be able to reuse them in API requests'/>.</p>
		</div>
	);
}

export default Suites;