import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Rest = props => {

	return (
		<div className = 'section'>

			<h1><FormattedMessage id="REST.MANAGEMENT"
								  defaultMessage='Rest API management'/></h1>

			<p>&nbsp;</p>

			<p><FormattedMessage id="REST.API.INTEGRATION"
								 defaultMessage='he integration of REST APIs in Tigmat has two objectives :'/></p>

			<ul>
				<li><FormattedMessage id="REST.API.INTEGRATION.1"
									  defaultMessage='Test the Rest APIs directly without going through HMI tests'/></li>
				<li><FormattedMessage id="REST.API.INTEGRATION.2"
									  defaultMessage='Use Rest APIs as prerequisites / postrequisites for tests suite'/></li>
			</ul>

			<h3><strong><FormattedMessage id="REST.OBJECT"
										  defaultMessage='API Rest object'/></strong></h3>

			<p><FormattedMessage id="REST.OBJECT.DESCRIPTION"
								 defaultMessage='A Rest API is made up of several parts'/></p>

			<h5><strong><FormattedMessage id="REST.NAME"
										  defaultMessage='Name'/></strong></h5>
			<p><FormattedMessage id="REST.NAME.DESCRIPTION"
								 defaultMessage='A Rest API is made up of several parts'/></p>
			<h5><strong><FormattedMessage id="REST.URI"
										  defaultMessage='URI'/></strong></h5>
			<p><FormattedMessage id="REST.URI.DESCRIPTION"
								 defaultMessage='The exact URL to test'/></p>
			<h5><strong><FormattedMessage id="REST.METHODS"
										  defaultMessage='Methods'/></strong></h5>
			<p><FormattedMessage id="REST.METHODS.DESCRIPTION"
								 defaultMessage='The possible methods are POST, GET, DELETE, PUT, PATCH'/></p>
			<h5><strong><FormattedMessage id="REST.PARAMETERS"
										  defaultMessage='Parameters'/></strong></h5>
			<p><FormattedMessage id="REST.PARAMETERS.DESCRIPTION.1"
								 defaultMessage='The parameters to be transmitted in the request'/></p>
			<p><FormattedMessage id="REST.PARAMETERS.DESCRIPTION.2"
								 defaultMessage='A parameter is a key/value pair'/></p>
			<h5><strong><FormattedMessage id="REST.HEADERS"
										  defaultMessage='Headers'/></strong></h5>
			<p><FormattedMessage id="REST.HEADERS.DESCRIPTION.1"
								 defaultMessage='The header of the request'/></p>
			<p><FormattedMessage id="REST.HEADERS.DESCRIPTION.2"
								 defaultMessage='As with the parameters, the value of an element of the header can have one or more entries, for each entry it is absolutely necessary to click on the Enter key to validate otherwise the entry is not taken into account'/></p>
			<h5><strong><FormattedMessage id="REST.BODY"
										  defaultMessage='Body'/></strong></h5>
			<p><FormattedMessage id="REST.BODY.DESCRIPTION"
								 defaultMessage='The body of the request in the form of json or xml, it can be sent in the case of the POST, PUT and PATCH methods'/></p>
			<h5><strong><FormattedMessage id="REST.AUTHORIZATION"
										  defaultMessage='Authorization'/></strong></h5>
			<p><FormattedMessage id="REST.AUTHORIZATION.DESCRIPTION"
								 defaultMessage='An API can be secure or not, there are several ways to secure an API, Tigmat supports the important ones.'/></p>

			<ul>
				<li><strong><FormattedMessage id="REST.AUTHORIZATION.INHERIT"
											  defaultMessage='INHERIT'/> : </strong>
					<FormattedMessage id="REST.AUTHORIZATION.INHERIT.DESCRIPTION"
									  defaultMessage='We can declare authentication at the suite level, all its tests can inherit from it'/>.</li>
				<li><strong><FormattedMessage id="REST.AUTHORIZATION.NOAUTH"
											  defaultMessage='NOAUTH'/> : </strong>
					<FormattedMessage id="REST.AUTHORIZATION.NOAUTH.DESCRIPTION"
									  defaultMessage='In the case of non-secure APIs, this is the default'/>.</li>
				<li><strong><FormattedMessage id="REST.AUTHORIZATION.BEARERTOKEN"
											  defaultMessage='BEARER_TOKEN'/> : </strong>
					<FormattedMessage id="REST.AUTHORIZATION.BEARERTOKEN.DESCRIPTION"
									  defaultMessage='Most APIs are secured with Oauth2, OpenId protocols. In this case, the token must be communicated to the API in order to be able to query it.'/>.</li>
				<li><strong><FormattedMessage id="REST.AUTHORIZATION.BASICAUTH"
											  defaultMessage='BASIC_AUTH'/> : </strong>
					<FormattedMessage id="REST.AUTHORIZATION.BASICAUTH.DESCRIPTION"
									  defaultMessage='This is the basic authorization that requires a login and a password'/>.</li>
				<li><strong><FormattedMessage id="REST.AUTHORIZATION.APIKEY"
											  defaultMessage='API_KEY'/> : </strong>
					<FormattedMessage id="REST.AUTHORIZATION.APIKEY.DESCRIPTION"
									  defaultMessage='This is another form of authorization, it requires a pair of key / value to be passed in the header or in the parameters of the request'/>.</li>
			</ul>

			<h5><strong><FormattedMessage id="REST.ASSERTIONS"
										  defaultMessage='Assertions'/></strong></h5>
			<p><FormattedMessage id="REST.ASSERTIONS.DESCRIPTION"
								 defaultMessage='Once the API is called, it is possible to test if the answer is correct'/></p>


			<h5><strong><FormattedMessage id="REST.RESPONSE.COLLECTION"
										  defaultMessage='Response Collection'/></strong></h5>
			<p><FormattedMessage id="REST.RESPONSE.COLLECTION.DESCRIPTION"
								 defaultMessage='We use this part to retrieve the answer of the request'/>.</p>
			<ul>
				<li>
					<strong><FormattedMessage id="REST.RESPONSE.COLLECTION.PATH"
											  defaultMessage='Response Path'/> :</strong>
					<FormattedMessage id="REST.RESPONSE.COLLECTION.PATH.DESCRIPTION"
									  defaultMessage='The path of data we want to retrieve in the response (json) for example user.name'/></li>
				<li>
					<strong><FormattedMessage id="REST.RESPONSE.COLLECTION.VARIABLE"
											  defaultMessage='Suite Variable'/> :</strong>
					<FormattedMessage id="REST.RESPONSE.COLLECTION.VARIABLE.DESCRIPTION"
									  defaultMessage='The variable in which the value of the answer will be stored, it can be retrieved in the variables of the suite'/></li>
			</ul>

		</div>
	);
}

export default Rest;