import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Users = props => {

	return (
		<div className='section'>

			<h1>
				<FormattedMessage id="USERS.MANAGEMENT"
								  defaultMessage='User Management'/>
			</h1>

			<p>&nbsp;</p>

			<h3><strong>
				<FormattedMessage id="USERS.CREATE.ADMIN.ACCOUNT"
								  defaultMessage='Create an admin account'/>
			</strong>
			</h3>

			<p><FormattedMessage id="USERS.TO.USE"
								 defaultMessage='To use Tigmat, it is necessary to create an administrator account. To do this, click on the "Sign up" link.'/>.<br/>
				<FormattedMessage id="USERS.FILL.ALL.FIELDS"
								  defaultMessage='Please fill out all fields'/>:</p>

			<ol>
				<li><FormattedMessage id="USERS.USERNAME.UNIQUE"
									  defaultMessage='Username: Your login must be unique'/>.
				</li>
				<li><FormattedMessage id="USERS.EMAIL"
									  defaultMessage='E-mail'/></li>
				<li><FormattedMessage id="USERS.COMPANY.NAME"
									  defaultMessage='Company Name'/></li>
				<li><FormattedMessage id="USERS.PASSWORD.SIZE"
									  defaultMessage='Password: must have at least 4 characters'/></li>
				<li><FormattedMessage id="USERS.PASSWORD.CONFIRMATION"
									  defaultMessage='Password Confirmation'/></li>
			</ol>

			<p><FormattedMessage id="USERS.AFTER.REGISTRING"
								 defaultMessage='Upon validation, you will receive a link via email to confirm the creation of your account. Once confirmed, you will be able to log in and start adding members of your team, then create your first project'/>.<br/>
			</p>
			<br/>
			<h3><strong><FormattedMessage id="USERS.H3.PROFILES"
										  defaultMessage='User profiles'/></strong></h3>

			<p><FormattedMessage id="USERS.FOUR.PROFILES"
								 defaultMessage='Tigmat application uses four types of user profiles, each with specific permissions, as shown in the following table'/>
			</p>

			<table>
				<thead>
				<tr>
					<td>&nbsp;</td>
					<td>&nbsp; &nbsp; &nbsp; Admin&nbsp; &nbsp; &nbsp;</td>
					<td>&nbsp; &nbsp; &nbsp; Manager&nbsp; &nbsp;&nbsp;</td>
					<td>&nbsp; &nbsp; &nbsp; Tester&nbsp; &nbsp;&nbsp;</td>
					<td>&nbsp; &nbsp; &nbsp; User</td>

				</tr>
				</thead>
				<tbody>
				<tr>
					<td><FormattedMessage id="USERS.PAYMENT.MANAGEMENT"
										  defaultMessage='Payment management'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.MANAGEMENT"
										  defaultMessage='User Management'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.PROJECT.MANAGEMENT"
										  defaultMessage='Project management'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.DASHBOARD"
										  defaultMessage='Dashboard'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;✔︎</td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.TEST.DOMAIN.MANAGEMENT"
										  defaultMessage='Tests and domains management'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp;<FormattedMessage id="USERS.VIEW.ONLY"
													   defaultMessage='View only'/></td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.SUITES.MANAGEMENT"
										  defaultMessage='Suites management'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp;<FormattedMessage id="USERS.VIEW.ONLY"
													   defaultMessage='View only'/></td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.SPRINTS.ITERATIONS.MANAGEMENT"
										  defaultMessage='Sprints and iterations management'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp;<FormattedMessage id="USERS.VIEW.ONLY"
													   defaultMessage='View only'/></td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.ACCESS.REPORTS"
										  defaultMessage='Access to test reports'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.WATCH.VIDEOS"
										  defaultMessage='Watch the videos'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
				</tr>
				<tr>
					<td><FormattedMessage id="USERS.MANUAL.EXECUTION"
										  defaultMessage='Manual execution of tests'/></td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ✔︎</td>
					<td>&nbsp;</td>
				</tr>

				</tbody>
			</table>

			<br/>
			<h3><FormattedMessage id="USERS.USER.MANAGEMENT"
								  defaultMessage='User Management'/></h3>

			<p><FormattedMessage id="USERS.USER.INDEPENDANT.PROJECT"
								 defaultMessage='Users management is separated from project management, allowing you to efficiently manage the members of each team for a given project'/>.</p>
			<p><FormattedMessage id="USERS.USER.INDEPENDANT.PROJECT.EXAMPLE"
								 defaultMessage='For example, after creating users and projects, you can assign users from the same team to the project they are working on'/>.</p>

			<h3><strong><FormattedMessage id="USERS.CREATE.NEW.USER"
										  defaultMessage='Creation of a new user'/></strong></h3>

			<p><FormattedMessage id="USERS.HOW.CREATE.NEW.USER"
								 defaultMessage='To create a new user: Administration -> Users -> New User'/></p>

			<p><FormattedMessage id="USERS.FILL.DIFFERENT.FIELDS"
								 defaultMessage='Fill in the different fields by assigning the correct rights and save'/>.</p>

			<p><FormattedMessage id="USERS.USER.RECEIVE.VALIDATION"
								 defaultMessage='The user will receive a validation link via email which will allow them to choose a password. After validating this link, the user will be able to log into their account.'/>.</p>

			<h3><strong><FormattedMessage id="USERS.CREATE.CICD.ACCOUNT"
										  defaultMessage='Creation of a CICD account'/></strong></h3>

			<p><FormattedMessage id="USERS.TO.LAUNCH.TESTS"
								 defaultMessage='To launch tests from a continuous integration tool, you can create CICD access keys: My Account -> Generate'/></p>

			<h3><strong><FormattedMessage id="USERS.MODIFICATION.DELETION.ACCOUNT"
										  defaultMessage='Modification &amp; deletion of an account'/></strong></h3>

			<p><FormattedMessage id="USERS.MODIFICATION.ACCOUNT"
								 defaultMessage=''/>.
			</p>
			<p><FormattedMessage id="USERS.MODIFICATION.MYACCOUNT"
								 defaultMessage=''/>.
			</p>
			<p><FormattedMessage id="USERS.DELETION.MYACCOUNT"
								 defaultMessage=''/>.
			</p>

		</div>
	);
}

export default Users;