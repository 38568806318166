import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {LayoutProvider} from '../_metronic/layout/core'
import {DocsPage} from './DocsPage'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import {I18nProvider} from "../_metronic/i18n/i18nProvider";

type Props = {
    basename: string
}

const App: React.FC<Props> = ({basename}) => {
    return (
        <BrowserRouter basename={basename}>
            <I18nProvider>
                <LayoutProvider>
                    <MasterLayout>
                        <DocsPage/>
                    </MasterLayout>
                </LayoutProvider>
            </I18nProvider>
        </BrowserRouter>
    )
}

export {App}
