import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Execute = props => {

	return (
		<div className = 'section'>

			<h2><FormattedMessage id="EXECUTION.OF.TESTS"
								  defaultMessage='Execution of tests'/></h2>

			<p>&nbsp;</p>

			<h3><strong><FormattedMessage id="EXECUTION.SPRINT.MANAGEMENT"
										  defaultMessage='Sprint management'/></strong></h3>

			<p><FormattedMessage id="EXECUTION.SPRINT.DESCRIPTION"
								 defaultMessage='The Tigmat application allows you to manage test campaigns in Agile Scrum mode'/>.</p>

			<p><FormattedMessage id="EXECUTION.ITERATION.DESCRIPTION"
								 defaultMessage='The execution of the tests is done as part of a sprint, each execution is represented by an iteration'/>.</p>

			<p><FormattedMessage id="EXECUTION.TO.CREATE.SPRINT"
								 defaultMessage='To create a sprint'/>
				<strong> <FormattedMessage id="EXECUTION.HOW.CREATE.SPRINT"
										  defaultMessage='Sprint -> add a sprint'/></strong></p>

			<p><FormattedMessage id="EXECUTION.GIVE.NAME.DESCRIPTION"
								 defaultMessage='Give a name and description to the sprint then validate'/>.</p>

			<h3><strong><FormattedMessage id="EXECUTION.MANUAL"
										  defaultMessage='Manual tests execution'/></strong></h3>

			<h2><FormattedMessage id="EXECUTION.ADD.ITERATION"
								  defaultMessage='Add an iteration'/></h2>

			<p><FormattedMessage id="EXECUTION.CREATE.ITERATION"
								 defaultMessage='Once the sprint is created. You can create an iteration that represents a run by clicking the add iteration button'/>.</p>
			<ul>
				<li><strong><FormattedMessage id="EXECUTION.CREATE.ITERATION1"
									  defaultMessage='Iteration'/></strong>
					<FormattedMessage id="EXECUTION.CREATE.ITERATION1.DES"
									  defaultMessage='the iteration is created with all the sequences'/>
				</li>
				<li><strong><FormattedMessage id="EXECUTION.CREATE.ITERATION2"
											  defaultMessage='Custom Iteration'/></strong>
					<FormattedMessage id="EXECUTION.CREATE.ITERATION2.DES"
									  defaultMessage='You can fill in the name of the iteration and choose the suites to include'/>
				</li>
			</ul>

			<p><FormattedMessage id="EXECUTION.GIVE.ITERATION.NAME.DESCRIPTION"
								 defaultMessage="The iteration is created with project's default values, e.g. browser, version, device group..."/>.</p>

			<p><FormattedMessage id="EXECUTION.ITERATION.EDIT.PAGE"
								 defaultMessage='After validation, the iteration edition page is displayed. You can edit the name, description and all other execution options'/>.</p>

			<h2><FormattedMessage id="EXECUTION.ADD.REMOVE.SUITES"
								  defaultMessage='Add/Remove Suites'/></h2>

			<p><FormattedMessage id="EXECUTION.CUSTOM.SUITES.ITERATION"
								 defaultMessage='To customize the suites to be included in the iteration, in the Suites tab, click on Add Suites and
				check the desired suites'/>.</p>

			<p><FormattedMessage id="EXECUTION.TO.DELETE.SUITES"
								 defaultMessage='To delete suites, click on the Delete button in front of the suite concerned'/>.</p>

			<h2><FormattedMessage id="EXECUTION.EXECUTION"
								  defaultMessage='Execution'/></h2>

			<p><FormattedMessage id="EXECUTION.REMEMBER.CREATE.PROJECT"
								 defaultMessage='Remember that when you created a web project, you already chose the default browser'/>.</p>

			<p><FormattedMessage id="EXECUTION.BEFORE.LAUNCHING.EXECUTION"
								 defaultMessage='Before launching the execution, you can change the browser for the current iteration'/>.</p>

			<p><FormattedMessage id="EXECUTION.CLICK.EXECUTION.BUTTON"
								 defaultMessage='Click on the Execution button in front of the desired iteration'/>.</p>

			<h3><strong><FormattedMessage id="EXECUTION.AUTOMATIC.TEST.EXECUTION"
										  defaultMessage='Automatic test execution'/></strong></h3>

			<p><FormattedMessage id="EXECUTION.TO.ALLOW.AUTOMATIC.LAUNCH"
								 defaultMessage='To allow the automatic launch of tests'/>&nbsp;
					 <strong><FormattedMessage id="EXECUTION.HOW.ALLOW.AUTOMATIC.LAUNCH"
											   defaultMessage='Administration -> Project management -> Edit the desired project'/></strong>.</p>

			<ol>
				<li>
					<FormattedMessage id="EXECUTION.HOW.ALLOW.AUTOMATIC.LAUNCH.1"
									  defaultMessage='Administration -> Project management -> Edit the desired project'/>
				</li>
				<li>
					<FormattedMessage id="EXECUTION.HOW.ALLOW.AUTOMATIC.LAUNCH.2"
									  defaultMessage='Administration -> Project management -> Edit the desired project'/>
				</li>
				<li>
					<FormattedMessage id="EXECUTION.HOW.ALLOW.AUTOMATIC.LAUNCH.3"
									  defaultMessage='Administration -> Project management -> Edit the desired project'/>
				</li>
			</ol>

			<h3><strong><FormattedMessage id="EXECUTION.FROM.CICD.TOOL"
										  defaultMessage='Execution from a CICD tool'/></strong></h3>

			<p><FormattedMessage id="EXECUTION.TO.LAUNCH.TEST.EXECUTION.FROM.CICD.TOOL"
								 defaultMessage='To launch test execution from a continuous integration tool (i.e. Jenkins, Gitlab, CircleCI, ...)'/>.</p>

			<ol>
				<li>
					<FormattedMessage id="EXECUTION.CREATE.CICD.ACCOUNT"
									  defaultMessage='Create a CICD account'/></li>
				<li>
				<p><FormattedMessage id="USERS.NAME.PASSWORD.REQUESTED"
									 defaultMessage='In this case, only the account name and password are requested'/></p>

				<p><FormattedMessage id="USERS.CICD.EXEPLE"
									 defaultMessage='For example, you can use this shell script :'/></p>

				<p><FormattedMessage id="USERS.CICD.CURL"
									 defaultMessage='curl -X PUT https://cicd.tigmat.io/cicd/exec/YOUR_PROJECT_ID -H "accessKey: CICD_ACCESS_KEY" -H "secretKey:CICD_SECRET_KEY"'/>.</p>
				<p><FormattedMessage id="USERS.CICD.OVERRIDE"
									 defaultMessage='For Web Projects, You can override some parameters : platform, browser and version, for that you can add -d platform=PLATFORM -d browser=BROWSER -d version=VERSION to request'/></p>

				<p><FormattedMessage id="USERS.CICD.VALUES"
									 defaultMessage='The possible values'/></p>
					<ul>
						<li>
							<FormattedMessage id="USERS.CICD.PLATFORM"
											  defaultMessage=''/></li>
						<li>
							<FormattedMessage id="USERS.CICD.BROWSER"
											  defaultMessage=''/></li>
						<li>
							<FormattedMessage id="USERS.CICD.VERSION"
											  defaultMessage=''/></li>
					</ul>
				</li>
			</ol>


		</div>
	);
}

export default Execute;