import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Extension = props => {

	return (
		<div className = 'section'>

			<h1><FormattedMessage id="DOCS.MENU.PREREQUISITE"
								  defaultMessage='Prerequisite'/></h1>

			<p>&nbsp;</p>
			<p><FormattedMessage id="PREREQUISITE.DESCRIPTION"
								 defaultMessage=''/></p>

			<ol>
				<li>
			<h4><strong><FormattedMessage id="PREREQUISITE.INSTALL.EXTENSION"
										  defaultMessage='Install the Tigmat extension'/></strong></h4>
			<br/>
			<ul>
				<li><p><FormattedMessage id="PREREQUISITE.GO.STORE.EXTENSION"
										 defaultMessage='Go to the extension page on Web store '/>
					(<a
						target='_blank'
						href = "https://chrome.google.com/webstore/detail/tigmat-ide-the-functional/chjoocjagcohmngaiciohfcjohfdaemg">Chrome</a>,
					<a
						target='_blank'
						href = "https://addons.mozilla.org/en-US/firefox/addon/tigmat-ide/"> Firefox</a>,
					<a
						target='_blank'
						href = "https://microsoftedge.microsoft.com/addons/detail/mlmglfbigjdofppdfgifdeopcijljnbe"> Edge</a>).</p>
				</li>
				<li><p><FormattedMessage id="PREREQUISITE.CLICK.ADD.EXTENSION"
										 defaultMessage='Click on the Add to Chrome/Firefox/Edge button'/></p>
				</li>
				<li><p><FormattedMessage id="PREREQUISITE.CLICK.ADD.EXTENSION.PERMISSIONS"
										 defaultMessage='For the proper functioning of the extension, accept all requested permissions'/></p>
				</li>
			</ul>
				</li>

				<li>
			<h4><strong><FormattedMessage id="PREREQUISITE.CONFIGURE.EXTENSION"
										  defaultMessage='Configure extension'/></strong></h4>
			<br/>
					<ul>
						<li>
							<h4><strong>CHROME</strong></h4>
							<ul>
								<li><p><FormattedMessage id="PREREQUISITE.ACCESS.EXTENSION.CHROME"
														 defaultMessage='Access extensions by typing chrome://extensions'/></p>
								</li>
								<li><p><FormattedMessage id="PREREQUISITE.ENABLE.INCOGNITO.MODE.CHROME"
														 defaultMessage='Access extension details and allow private mode and access to file URLs'/></p>
								</li>
								<li><p><FormattedMessage id="PREREQUISITE.PIN.EXTENSION.CHROME"
														 defaultMessage='Click on the extension button at the top right and pin the Tigmat extension'/></p>
								</li>
							</ul>
						</li>
						<li>
							<h4><strong>FIREFOX</strong></h4>
							<ul>
								<li><p><FormattedMessage id="PREREQUISITE.ACCESS.EXTENSION.FIREFOX"
														 defaultMessage='Access extensions by typing about:addons'/></p>
								</li>
								<li><p><FormattedMessage id="PREREQUISITE.ENABLE.INCOGNITO.MODE.FIREFOX"
														 defaultMessage='Accept permissions and incognito mode'/></p>
								</li>
							</ul>
						</li>
						<li>
							<h4><strong>EDGE</strong></h4>
							<ul>
								<li><p><FormattedMessage id="PREREQUISITE.ACCESS.EXTENSION.EDGE"
														 defaultMessage='Access extensions by typing about:addons'/></p>
								</li>
								<li><p><FormattedMessage id="PREREQUISITE.ENABLE.INCOGNITO.MODE.EDGE"
														 defaultMessage='Accept permissions and incognito mode'/></p>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ol>
		</div>
	);
}

export default Extension;