import React from "react";
import {FormattedMessage} from "react-intl";

// import "./style/style.css";

const Tests = props => {

	return (
		<div className = 'section'>

			<h1><FormattedMessage id="TESTS.MANAGEMENT"
								  defaultMessage='Tests management'/></h1>

			<p>&nbsp;</p>

			<h3><strong><FormattedMessage id="TESTS.DOMAINS.MANAGEMENT"
										  defaultMessage='1. Domain management'/></strong></h3>

			<p><FormattedMessage id="TESTS.DOMAIN.ALLOW.GROUP.TESTS"
								 defaultMessage='A domain allows you to group tests of the same category (for example for an ecommerce site you can
				have the following domains: invoice, order, customers, ...)'/></p>

			<p><FormattedMessage id="TESTS.CREATE.DOMAIN"
								 defaultMessage='To create a domain, on the menu on the left, click'/>
			<strong> <FormattedMessage id="TESTS.HOW.CREATE.DOMAIN"
										 defaultMessage='Domains -> add a domain.'/></strong></p>

			<p><FormattedMessage id="TESTS.GIVE.DOMAIN.NAME.DESCRIPTION"
								 defaultMessage='Give a name to the domain then validate.
				The domain is created, you can enter a description and add tests to it'/>.</p>

			<h3><strong><FormattedMessage id="TESTS.CREATE.TEST"
										  defaultMessage='2. Test creation'/></strong></h3>

			<p><FormattedMessage id="TESTS.CREATE.OR.IMPORT.TEST"
								 defaultMessage='Once the domain is created. You can create or import tests but also you can launch the plugin
				from the application to directly record your tests'/>.</p>

			<h3><FormattedMessage id="TESTS.TEST.RECORDING.WEB"
								  defaultMessage='2.1. Recording of web tests'/></h3>

			<p><FormattedMessage id="TESTS.INSTALL.EXTENSION"
								 defaultMessage='First, install our extension to start recording tests on your browser'/> (<a
				target='_blank'
				href = "https://chrome.google.com/webstore/detail/tigmat-ide-the-functional/chjoocjagcohmngaiciohfcjohfdaemg">Chrome</a>, <a
				target='_blank'
				href = "https://addons.mozilla.org/en-US/firefox/addon/tigmat-ide/">Firefox</a>).</p>

			<p><FormattedMessage id="TESTS.HOW.CREATE.TEST"
								 defaultMessage='The easiest way to create one or more tests is to start recording your scenarios, this part is
				completely automated and does not require any human intervention <strong>Domains -> click on a
					domain -> Tab: Test cases -> Recording</strong>'/>.</p>

			<p><FormattedMessage id="TESTS.SCROLL.DOWN.SCENARIO"
								 defaultMessage='Scroll down your scenario, you can add as many tests as you want. Once finished, you can save by
				clicking on the Save button'/>.</p>

			<p><FormattedMessage id="TESTS.TESTS.DISPLAYED.ON.PAGE"
								 defaultMessage='Hop, it is finished, your tests that you have just created are displayed on your page, you can now use
													them to create suites and launch the execution'/>.</p>




			<h3><FormattedMessage id="TESTS.TEST.RECORDING.MOBILE"
								  defaultMessage='2.2. Web test recording'/></h3>

			<p><FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_1"
								 defaultMessage='The easiest way to create one or more tests is to start recording your scenarios, this part is completely automated and does not require any human intervention Domains -> click on a domain -> Tab: Test cases -> Recording'/>.</p>
			<p><FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_2"
								 defaultMessage='Once your environment is ready, a new page opens. It displays your application in simulation. You can interact with the application to create tests :'/>.</p>
			<ul>
				<li>
					<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_3"
										 defaultMessage='Your application is displayed on the left'/>
				</li>
				<li>
					<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_4"
									  defaultMessage='At the top is displayed the time you have left according to the offer you have chosen.'/>
				</li>
				<li>
					<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_5"
									  defaultMessage='The buttons displayed on top :'/>
					<ul>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_6"
											  defaultMessage='Back'/>
						</li>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_7"
											  defaultMessage='Refresh : allows to refresh the page to have the real rendering of the application.'/>
						</li>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_8"
											  defaultMessage='Quit session : to close and quit the inspector.'/>
						</li>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_9"
											  defaultMessage='Save : allows to save the test(s) in the Tigmat application.'/>
						</li>
					</ul>
				</li>
				<li>
					<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_10"
									  defaultMessage='The Selected Element section, allows you to interact with the application :'/>
					<ul>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_11"
											  defaultMessage='Tap : click on a button, a link, ...'/>
						</li>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_12"
											  defaultMessage='Send keys : enter a text in a text box.'/>
						</li>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_13"
											  defaultMessage='Assert : check the expected result.'/>
						</li>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_14"
											  defaultMessage='Clear : empty the selection.'/>
						</li>
						<li>
							<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_15"
											  defaultMessage='Copy : copy attributes of the selected element.'/>
						</li>
					</ul>
				</li>
				<li>
					<FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_16"
									  defaultMessage='The Recorder section displays user actions'/>
				</li>
			</ul>
			<p><FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_17"
								 defaultMessage='Each action made on the application must be confirmed by a click on the corresponding button.'/></p>
			<p><FormattedMessage id="TESTS.TEST.RECORDING.MOBILE_18"
								 defaultMessage='Once you finish recording your tests, click on the Save button to save all your tests in the Tigmat application.'/></p>


			<h3><FormattedMessage id="TESTS.IMPORT.TESTS"
								  defaultMessage='2.3. Import tests'/></h3>

			<p><FormattedMessage id="TESTS.HOW.IMPORT.TESTS"
								 defaultMessage='To import tests (previously saved using the chrome / firefox plugin) <strong>Domains -> a domain
				-> Tab: Test cases -> import tests.</strong>'/></p>

			<h3><FormattedMessage id="TESTS.CREATE.TESTS.MANUALY"
								  defaultMessage='2.4. Creation of tests manually'/></h3>

			<p><FormattedMessage id="TESTS.HOW.ADD.TEST.TO.DOMAIN"
								 defaultMessage='To add a test <strong>Domains -> a domain -> Tab: test cases -> New Test</strong>'/></p>

			<p><FormattedMessage id="TESTS.GIVE.TEST.NAME.DESCRIPTION"
								 defaultMessage='Give a name and validate the test editing page appears. You can add a description to the test'/>.</p>

			<h3><FormattedMessage id="TESTS.ADD.INSTRUCTION"
								  defaultMessage='2.4.1. Add Instruction'/></h3>

			<p><FormattedMessage id="TESTS.HOW.ADD.INSTRUCTION"
								 defaultMessage='A test consists of one or more test steps (instructions and assertions).
								 To create an instruction, use the tests steps tab.'/></p>
			<p><FormattedMessage id="TESTS.HOW.ADD.INSTRUCTION2"
								 defaultMessage='Click on Add Instruction and fill in the different fields'/>:</p>

			<ol>
				<li><FormattedMessage id="TESTS.TEST.NAME.DESCRIPTION"
											  defaultMessage='Test step name'/></li>
				<li><FormattedMessage id="TESTS.ACTION.DESCRIPTION"
									  defaultMessage='<strong>Action</strong>: The command to use to manipulate the element (click, enter in a text
					field, selection from a drop-down list, validate a popup, ...)'/>
				</li>
				<li><FormattedMessage id="TESTS.COMPONENT.DESCRIPTION"
									  defaultMessage='<strong>Component</strong>: A unique identifier of the element in the page on which the action
					takes place (id, name, link, xpath, cssSelector, ...)'/>
				</li>
				<li><FormattedMessage id="TESTS.COMPONENT.NAME.DESCRIPTION"
									  defaultMessage='<strong>Component name</strong>: component value'/></li>
				<li><FormattedMessage id="TESTS.VALUE.DESCRIPTION"
									  defaultMessage='<strong>Value</strong>: some actions need a value, for example the value to enter in a text field'/>
				</li>
			</ol>

			<h3><FormattedMessage id="TESTS.ADD.ASSERT"
								  defaultMessage='2.4.2. Add Assert (expected result)'/></h3>

			<p><FormattedMessage id="TESTS.HOW.CREATE.ASSERT"
								 defaultMessage='After a certain number of instructions, the expected result must be checked (assert test). To create
				an assert, click on Add TestAssert and fill in the following fields'/>:</p>

			<ol>
				<li><FormattedMessage id="TESTS.ASSERT.NAME.DESCRIPTION"
									  defaultMessage='<strong>Name</strong>: description of expected result'/></li>
				<li><FormattedMessage id="TESTS.ASSERT.OPERATION.DESCRIPTION"
									  defaultMessage='<strong>Operation</strong>: the operation to check, for example, the existence of an element in
					the page, an element contains or not a text ...'/>
				</li>
				<li><FormattedMessage id="TESTS.ASSERT.COMPONENT.DESCRIPTION"
									  defaultMessage='<strong>Component</strong>: the unique identifier of the element (id, name, link, xpath,
					cssSelector, ...)'/>
				</li>
				<li><FormattedMessage id="TESTS.ASSERT.COMPONENT.NAME.DESCRIPTION"
									  defaultMessage='<strong>Component name</strong>'/></li>
				<li><FormattedMessage id="TESTS.ASSERT.VALUE.DESCRIPTION"
									  defaultMessage='<strong>Value</strong>: some operations need a value, for example the text of an element of the page'/>
				</li>
			</ol>

			<h3><FormattedMessage id="TESTS.IMPORT.EXPORT"
								  defaultMessage='2.4.3. Import Export'/></h3>

			<p><FormattedMessage id="TESTS.HOW.IMPORT.EXPORT"
								 defaultMessage='On the same (test steps) tab, you can import test steps or export the current test'/>.</p>

			<br/>

			<h1><FormattedMessage id="TESTS.TRICKS"
								  defaultMessage='Tricks'/></h1>

			<p><FormattedMessage id="TESTS.TRICKS.1"
								 defaultMessage='With Tigmat, you can replay the same tests without modifying them'/>.</p>
			<p><FormattedMessage id="TESTS.TRICKS.2"
								 defaultMessage='For example, in the case of a product creation test, the product name must be unique. Running the test twice will generate an error'/>.</p>
			<p><FormattedMessage id="TESTS.TRICKS.3"
								 defaultMessage='To remedy this problem, add three (###) to the field name. At each execution, Tigmat will replace the three # by a unique number'/>.</p>
			<p><FormattedMessage id="TESTS.TRICKS.4"
								 defaultMessage='With Tigmat, you can replay the same tests without modifying them'/>.</p>


		</div>
	);
}

export default Tests;